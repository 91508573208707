<template>
  <div class="material">
    <div class="center-title">个人资料</div>
    <div class="img-box">
      <img v-if="showAvatar!=''" class="user-img" :src="showAvatar" alt="">
      <img v-else class="user-img" :src="require('@/assets/head-default-personal.png')" alt="">
      <label class="replace">更换<input class="file-input" type="file" name="upload" accept="image/gif,image/jpeg,image/jpg,image/png" @change="fileChange"></label>
    </div>
    <div class="form">
      <div class="form-box">
        <div class="form-title">真实姓名</div>
        <div class="form-text">
          <input class="form-input" type="text" v-model="newName">
        </div>
      </div>
      <!-- <div class="form-box">
        <div class="form-title">所在公司</div>
        <div class="form-text">
          <input class="form-input" type="text">
        </div>
      </div>
      <div class="form-box">
        <div class="form-title">所属部门</div>
        <div class="form-text">
          <input class="form-input" type="text">
        </div>
      </div>
      <div class="form-box">
        <div class="form-title">担任职务</div>
        <div class="form-text">
          <input class="form-input" type="text">
        </div>
      </div>
      <div class="form-box">
        <div class="form-title">所在地区</div>
        <div class="form-text">
          <input class="form-input" type="text">
        </div>
      </div>
      <div class="form-box">
        <div class="form-title">详细地址</div>
        <div class="form-text">
          <input class="form-input" type="text" v-model="userInfo.address">
        </div>
      </div> -->
      <div class="form-box">
        <div class="form-title">用户昵称</div>
        <div class="form-text">
          <input class="form-input" type="text" v-model="newNickname">
        </div>
      </div>
    </div>
    <button class="sub-btn" @click="subBtn">保存</button>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: 'Material',
  data(){
    return {
      showAvatar: '', //头像
      newName: '',
      newNickname: ''
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  mounted(){
    this.getUserInfo1();//获取用户信息
    
    if(this.userInfo){
      this.newName = this.userInfo.name;
      this.newNickname = this.userInfo.nickname;
    }
  },
  methods: {
    ...mapMutations(['ShowTipModal', 'GetUserInfo']), //tip弹窗
    //获取用户信息
    getUserInfo1(){
      this.$axios.post(`/v1/personal/userInfo`, {}, {useLog: true}).then(res => {
        if(res.code == 0){
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.GetUserInfo();
          this.showAvatar = this.userInfo.avatar;
        }
      })
    },
    //更换头像
    fileChange(e){
      let _this = this;
      let file = e.target.files[0];
      let reader = new FileReader();

      reader.onload = function(e){
          _this.showAvatar = e.target.result;
      }
      reader.readAsDataURL(file);
    },
    //保存
    subBtn(){
      let data = {
        nickname : this.newNickname,
        name : this.newName
      }

      if(!this.showAvatar.includes('http')){
        data.avatar = this.showAvatar;
      }

      this.$axios.post(`/v1/personal/updateInfo`, data, {useLog: true}).then(res => {
        if(res.code == 0){
          let _this = this;
          this.ShowTipModal({
            text: '修改成功',
            fun: function(){
              _this.getUserInfo1();
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.img-box{
  width: 130px;
  margin-bottom: 20px;
  position: relative;
}
.user-img{
  display: block;
  width: 98px;
  height: 98px;
  border-radius: 50%;
  float: none;
}
.form-box{
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  .form-title{
    font-size: 16px;
    color: #666;
  }
  .form-text{
    width: 290px;
    margin-left: 20px;
  }
}
.sub-btn{
  width: 114px;
  height: 40px;
  line-height: 40px;
  background: #254ED4;
  border-radius: 6px;
  font-size: 16px;
  color: #FFF;
  margin-top: 40px;
  cursor: pointer;
}
.replace{
  font-size: 8px;
  color: #999;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  .file-input{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
}
</style>
